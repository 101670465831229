import { Badge } from "reactstrap";
import { formatNumberAsCurrency } from "../../../utils";

const PriceBreakup = ({ departure, duration, price }) => {
    return (
        <div className="rounded-4 mb-3">
            <small className="text-">Starting From</small>
            <div className="d-flex mb-2 justify-content-between align-items-center">
                <h3 className="text-primary">₹ {formatNumberAsCurrency(price)}</h3>
                <Badge
                    className="text-dark px-3 py-2"
                    color="light"
                    pill
                >
                    Per Person
                </Badge>
            </div>
        </div>
    )
}

export default PriceBreakup;