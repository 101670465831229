import React, { useState } from 'react';
import { Row } from 'reactstrap';
import MonthButton from './MonthButton';
import DateRange from './DateRange';

const ShowDates = ({ dates, yearMonths }) => {
    const [currentYearMonth, setSelectedYearMonth] = useState(yearMonths[0]);

    const handleMonthChange = (monthYear) => {
        setSelectedYearMonth(monthYear);
    };

    const showMonths = yearMonths.map(yearMonth => (
        <MonthButton
            isActive={currentYearMonth === yearMonth}
            key={yearMonth}
            onClick={handleMonthChange}
            yearMonth={yearMonth}
        />)
    )

    const showDates = dates
        .filter(({ start_date }) => start_date.includes(currentYearMonth))
        .map(({ start_date, end_date }) => (
            <DateRange
                key={start_date + end_date}
                start_date={start_date}
                end_date={end_date}
            />
        ))

    return (
        <div className="my-4 bg-white p-4 rounded-4">
            <h5>Upcoming Departures</h5>
            <div className="d-flex gap-3 justify-content-center mb-4">
                {showMonths}
            </div>
            <Row>
                {showDates}
            </Row>
        </div>
    )
}

export default ShowDates