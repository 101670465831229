import useFetchData from '../../hooks/useFetchData';
import { Link } from 'react-router-dom';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

const NavDowndownItem = ({ key, category_type, setIsOpen }) => {
    const [isLoading, data] = useFetchData(`${process.env.REACT_APP_TRIPS_ENDPOINT}?_no_pagination=true&&status__in=active&category__in=${category_type}&_order_by=id`);

    if (isLoading)
        return <></>

    if (data.length === 0)
        return <></>

    return (
        <UncontrolledDropdown nav inNavbar className="me-4 hover-show">
            <DropdownToggle nav caret>
                {category_type}
            </DropdownToggle>

            <DropdownMenu center="true" className="drop-shadow hover-show-menu rounded-4 border">
                {
                    data.map(trip => (
                        <DropdownItem key={trip.id}>
                            <Link className="nav-link py-0" to={`/trip/${trip.id}`} onClick={() => setIsOpen(false)}>{trip.name}</Link>
                        </DropdownItem>
                    ))
                }
            </DropdownMenu>
        </UncontrolledDropdown>)
}

export default NavDowndownItem