import useFetchData from '../../../hooks/useFetchData';
import ItineraryCard from './ItineraryCard';

const Iternary = ({ tripId }) => {
    const [isLoading, data] = useFetchData(`${process.env.REACT_APP_ITERNARY_ENDPOINT}?_no_pagination=true&trip_id__in=${tripId}`);

    if (!isLoading && data.length > 0) {
        const itinerary = data[0].itinerary
        return (
            <div className="mb-4 bg-white p-4 rounded-4">
                <h5 className="mb-3">Day wise iternary</h5>
                <div>
                    <ItineraryCard itinerary={itinerary} />
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default Iternary;