import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
} from 'reactstrap';

import logo from '../../assets/images/logo.png';
import NavDropdown from './NavDropdown';

const Navigation = (args) => {
    const [link, setlink] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const handleLinkChange = (index) => {
        setlink(index)
        setIsOpen(false);
    }

    return (
        <div>
            <Navbar {...args}>
                <NavbarBrand href="/">
                    <img src={logo} alt="Backpacker Logo" style={{ width: "120px" }} />
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ms-auto" navbar>
                        <NavItem className="me-4 active">
                            <Link
                                className={`nav-link ${link === 1 ? "active" : ""}`}
                                to="/"
                                onClick={() => handleLinkChange(1)}
                            >
                                Home
                            </Link>
                        </NavItem>
                        <NavDropdown setIsOpen={setIsOpen} />
                        <NavItem className="me-4">
                            <Link
                                className={`nav-link ${link === 2 ? "active" : ""}`}
                                to="/aboutus"
                                onClick={() => handleLinkChange(2)}
                            >
                                About Us
                            </Link>
                        </NavItem>
                        <NavItem className="me-4">
                            <Link
                                className={`nav-link ${link === 3 ? "active" : ""}`}
                                to="/contactus"
                                onClick={() => handleLinkChange(3)}
                            ><i class="bi bi-telephone-fill"></i> Contact Us
                            </Link>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </div >
    );
}

export default Navigation;