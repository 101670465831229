import { useParams } from 'react-router-dom';
import useFetchData from '../../../hooks/useFetchData';
import { Row, Col } from 'reactstrap';

import Iternary from './Iternary';
import TripHeader from './TripHeader';
import TripSlider from './TripSlider';
import Departures from './Departures';
import Description from './Description';
import BottomDrawer from './BottomDrawer';
import Pricing from './Pricing';
import Clarification from './Clarification';

const Trip = () => {
    const { id } = useParams();
    const [isLoading, data] = useFetchData(`${process.env.REACT_APP_TRIPS_ENDPOINT}?_no_pagination=true&id__in=${id}`);
    const tripData = data.length ? data[0] : [];

    if (isLoading || data.length === 0)
        return <></>

    window.scrollTo(0, 0);

    return (
        <section className="bg-gray mt-nav">
            <BottomDrawer tripData={tripData} />
            <TripHeader
                title={tripData.title}
                seats={tripData.seats}
                duration={`${tripData.duration_in_days - 1}N / ${tripData.duration_in_days}D`}
                places_covered={tripData.places_covered}
            />
            <section className="container pb-5 px-sm-4">
                <Row>
                    <Col md="8">
                        <TripSlider gallery={tripData.photos} />
                        <Departures tripId={id} />
                        <Description description={tripData.description} />
                        <Iternary tripId={id} />
                        <Clarification tripId={id} />
                    </Col>
                    <Col className="d-none d-md-block" md="4">
                        <Pricing
                            tripId={id}
                            tripData={tripData}
                        />
                    </Col>
                </Row>
            </section>
        </section>
    )
}

export default Trip;