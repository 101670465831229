import { Row, Col } from 'reactstrap'
import { formatNumberAsCurrency } from "../../../utils";

const PriceExtras = ({ title, price }) => {
    return (
        <div className="mb-2">
            <Row>
                <Col xs="7" md="7"><small><span>{title}</span></small></Col>
                {price > 1 && <Col xs="5" md="5" className="text-end"><small>₹ {formatNumberAsCurrency(price)}</small></Col>}
            </Row>
        </div>
    )
}

export default PriceExtras;