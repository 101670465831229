import React from 'react';
import useFetchData from '../../../hooks/useFetchData';

const ListComponent = ({ datapoint, endpoint, tripId, title, icon, bg }) => {
    const [isLoading, data] = useFetchData(`${endpoint}?_no_pagination=true&trip_id__in=${tripId}`);

    if (!isLoading && data.length > 0) {
        const listData = data[0][datapoint.toLowerCase()];

        return (
            <div className={`mb-4 p-4 rounded-4 ${bg}`}>
                <h5 className="mb-3">{title}</h5>
                <ul className="list-unstyled">
                    {listData.map((item, index) => (
                        <li className="mb-3" key={index}>
                            <i className={`bi ${icon}`} style={{ marginRight: "8px" }}></i>
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
        );
    } else {
        return null; // Return null if there's no data
    }
};

export default ListComponent;