import useFetchData from '../../../hooks/useFetchData';
import HomeBanner from './HomeBanner';
import Slider from './Slider';
import Testimonials from './Testimonials';
import TripCardsScroller from './TripCardsScroller';

const Home = () => {
    const [isLoading, data] = useFetchData(`${process.env.REACT_APP_CATEGORIES_ENDPOINT}?_no_pagination=true&status__in=Active&_order_by=id`);

    if (isLoading)
        return <></>

    return (
        <>
            <HomeBanner />
            {data.map(category => <TripCardsScroller key={category.id} category_type={category.name} />)}
            <Testimonials />
            <Slider />
        </>
    )
}

export default Home;