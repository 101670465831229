import useFetchData from '../../../hooks/useFetchData';
import ReviewSlides from './ReviewSlides';

const Reviews = () => {
    const [isLoading, data] = useFetchData(`${process.env.REACT_APP_REVIEWS_ENDPOINT}?_no_pagination=true`);

    // Return nothing if loading
    if (isLoading)
        return <></>

    return (
        <>{console.log(data)}
            <ReviewSlides data={data} />
        </>
    )
}

export default Reviews