import { Row, Col } from 'reactstrap';
import ReviewsText from './ReviewsText';
import Reviews from './Reviews';

const Testimonials = () => {
    return (
        <section>
            <div className="container py-5 px-sm-4">
                <Row className="justify-content-between">
                    <Col className="mb-sm-4" md="5">
                        <ReviewsText />
                    </Col>
                    <Col md="6">
                        <Reviews />
                    </Col>
                </Row>
            </div>
        </section>
    );
}

export default Testimonials;
