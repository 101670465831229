import useFetchData from '../../../hooks/useFetchData';
import CardSwiper from './CardSwiper';

const TripCardsScroller = ({ category_type }) => {
    const [isLoading, data] = useFetchData(`${process.env.REACT_APP_TRIPS_ENDPOINT}?_no_pagination=true&&status__in=active&category__in=${category_type}&_order_by=id`);

    if (isLoading)
        return <></>

    if (data.length === 0)
        return <></>

    return (
        <section id="upcomingtrips">
            <div className="container py-5 px-sm-4">
                <div className="mb-4">
                    <h3 className='text-slate'>{category_type.toUpperCase()}</h3>
                </div>
                <CardSwiper trips={data} />
            </div >
        </section>
    );
};

export default TripCardsScroller;