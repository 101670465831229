import { useNavigate } from 'react-router-dom';

import {
    Card,
    CardBody,
} from 'reactstrap';

const TripCard = ({ trip }) => {
    // const { title, duration, seats, grade } = trip;
    // const price = trip.price_breakup[0].price;
    // const image = trip.gallery[0];

    // const imageUrl = "https://res.cloudinary.com/dgjllfp17/image/upload/c_scale,h_200,w_300/";

    const navigate = useNavigate();

    const handleCardClick = () => {
        // REMOVE - opens the trip page in new tab
        // window.open(`/trip/${trip.id}`, "_blank");
        navigate(`/trip/${trip.id}`);
    }

    return (
        <Card className='hovershadow-md md-4 sm-6 border border-0 rounded-4 h-100 bg-gray' onClick={handleCardClick}>
            <img
                className="rounded rounded-4"
                src={trip.cover_photo}
                alt={trip.title}
                style={{
                    width: "100%",
                    height: "200px"
                }}
            />
            <CardBody className="p-3 d-flex flex-column">
                <h6 className='h40'>{trip.title}</h6>
                <div>
                    <div className="d-flex justify-content-between mb-2">
                        <span className="text-primary" style={{ fontSize: "12px", fontWeight: "700" }}><i className="bi bi-calendar-check"></i> <small>{trip.duration_in_days - 1}N / {trip.duration_in_days}D</small></span>
                        <span className="text-primary" style={{ fontSize: "12px", fontWeight: "700" }}><i className="bi bi-person-circle"></i> <small>{trip.seats} Seats</small></span>
                        {/* TODO - Remove once we finalize single structure for all trips */}
                        {/* <span className="bg-white text-primary" style={{ fontSize: "12px", fontWeight: "700" }}><i className="bi bi-star-fill"></i> <small>Grade: {grade} </small></span> */}
                    </div>
                    <div>
                        <small style={{ fontSize: "12px", color: "gray" }}>Starting From</small>
                        <h6 className="text-slate">{`Rs. ${trip.starting_price} /-`}</h6>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default TripCard;