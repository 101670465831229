import ListComponent from "../ListComponent";

const Clarification = ({ tripId }) => (
    <>
        {/* Why Us? */}
        <ListComponent
            datapoint="description"
            endpoint={process.env.REACT_APP_WHY_ENDPOINT}
            tripId={tripId}
            title="Why Travel with Backpacker Tours?"
            icon="bi-suit-heart"
            bg="bg-white"
        />

        {/* Inclusions */}
        <ListComponent
            datapoint="inclusions"
            endpoint={process.env.REACT_APP_INCLUSION_ENDPOINT}
            tripId={tripId}
            title="Inclusions"
            icon="bi-check-circle text-success"
            bg="bg-white"
        />

        {/* Exclusions */}
        <ListComponent
            datapoint="exclusions"
            endpoint={process.env.REACT_APP_EXCLUSION_ENDPOINT}
            tripId={tripId}
            title="Exclusions"
            icon="bi-x-circle text-danger"
            bg="bg-white"
        />

        {/* Things To Carry */}
        <ListComponent
            datapoint="things_to_carry"
            endpoint={process.env.REACT_APP_THINGS_TO_CARRY_ENDPOINT}
            tripId={tripId}
            title="Things to Carry"
            icon="bi-bag"
            bg="bg-white"
        />

        {/* Please Note */}
        <ListComponent
            datapoint="note"
            endpoint={process.env.REACT_APP_PLEASENOTE_ENDPOINT}
            tripId={tripId}
            title="Please Note"
            icon="bi-dash"
            bg="bg-white"
        />

        {/* Cancellation Policy */}
        <ListComponent
            datapoint="cancellation"
            endpoint={process.env.REACT_APP_CANCELLATION_ENDPOINT}
            tripId={tripId}
            title="Cancellation Policy"
            icon="bi-dash"
            bg="text-white bg-danger"
        />
    </>
)

export default Clarification;