import React from 'react'
import { useState } from 'react';
import useFetchData from '../../../hooks/useFetchData';

import { Card } from 'reactstrap';
import { Button } from 'reactstrap';

import ShareModal from './ShareModal';
import PriceBreakup from './PriceBreakup';
import PriceExtras from './PriceExtras';
import Example from './InfoTooltip';

const Pricing = ({ tripId, tripData }) => {
    const [isLoading, data] = useFetchData(`${process.env.REACT_APP_PRICING_ENDPOINT}?_no_pagination=true&trip_id__in=${tripId}`);
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    if (!isLoading && data.length > 0) {
        const pricing = data[0];

        return (
            <div className="sticky-price-card">
                <ShareModal modal={modal} toggle={toggle} body="queryform" tripTitle={tripData.title} tripId={tripId} />
                <Card className="bg-white rounded-4 drop-shadow border-sm-0 drop-shadow-sm-0 p-sm-4">
                    <div className="rounded-4">
                        <h5 className="mb-4 d-md-none">Price Breakup</h5>
                        <PriceBreakup
                            departure={tripData.pickupdrop}
                            duration={`${tripData.duration_in_days - 1}N / ${tripData.duration_in_days}D`}
                            // TODO: write func to pick lowest price automatically
                            price={tripData.starting_price}
                        />
                        <hr className="mb-3 mt-0 dashed" />
                        <div className="mb-3">
                            <Example />
                            {pricing.quad > 0 && <PriceExtras
                                title="Quad"
                                price={pricing.quad}
                            />}
                            {pricing.tripple > 0 && <PriceExtras
                                title="Triple"
                                price={pricing.tripple}
                            />}
                            {pricing.double > 0 && <PriceExtras
                                title="Twin"
                                price={pricing.double}
                            />}
                        </div>
                        {/* {
                            discount && (<AlertBox icon="wallet2" text={discount} />)
                        } */}
                        <Button className="d-none d-md-block w-100 bg-primary text-white border border-0 rounded-3 mt-3" onClick={toggle}>
                            Send Inquiry
                        </Button>
                    </div>
                </Card>
            </div>
        )
    } else {
        return <></>
    }

}

export default Pricing;

