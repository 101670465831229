import { Button } from "reactstrap";
import { monthLookup } from "../../../../utils";

const MonthButton = ({ yearMonth, isActive, onClick }) => {
    const [year, monthIndex] = yearMonth.split("-");
    const month = monthLookup[monthIndex];

    return (
        <Button
            className={isActive ? "btn-date-active" : "btn-date"}
            onClick={() => onClick(yearMonth)}>
            {month} {year}
        </Button>
    )
}

export default MonthButton