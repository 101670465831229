import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

function Example(args) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <div>
            <h6>
                Occupancy <i class="bi bi-info-circle-fill fs-6" style={{ "color": "#b7b7b7bf" }} id="TooltipExample"></i>
            </h6>
            <Tooltip
                {...args}
                isOpen={tooltipOpen}
                target="TooltipExample"
                toggle={toggle}
                placement='bottom'
            >
                <small>Quad - Room will be shared between four people.</small>
                <br></br>
                <small>Triple - Room will be shared between three people.</small>
                <br></br>
                <small>Twin - Room will be shared between two people.</small>
                <br></br>
            </Tooltip>
        </div >
    );
}

Example.args = {
    autohide: true,
    flip: true,
    placement: 'bottom'
};

Example.argTypes = {
    placement: {
        control: { type: 'select' },
        options: ['top', 'left', 'right', 'bottom'],
    },
};

export default Example;