import useFetchData from '../../../../hooks/useFetchData';
import ShowDates from './ShowDates';

const Departures = ({ tripId }) => {
    const [isLoading, data] = useFetchData(`${process.env.REACT_APP_DEPARTURES_ENDPOINT}?_no_pagination=true&tripid__in=${tripId}`);

    if (!isLoading && data.length > 0) {
        const dates = data[0].dates;
        const yearMonths = dates.reduce((sum, date) => {
            console.log(date.start_date.slice(0, -3));
            const ym = date.start_date.slice(0, -3);

            if (sum.indexOf(ym) === -1)
                sum.push(ym);

            return sum;
        }, [])

        return (
            <ShowDates yearMonths={yearMonths} dates={dates} />
        )
    } else {
        <></>
    }
}

export default Departures;