import React, { useState, useEffect } from 'react';
import { Outlet } from "react-router-dom";

import Footer from './Footer';
import Navigation from './Navigation';

const Layout = () => {
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        console.log('getting called..')
        if (window.scrollY > 70) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    return (
        <>
            <Navigation className={`shadow-md bg-white ${scrolled ? 'shadow-md' : ''}`} expand="md" horizontal="end" fixed="top" />
            <Outlet />
            <Footer />
        </>
    )
}

export default Layout