import { useState } from 'react';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Badge,
} from 'reactstrap';

const ItineraryCard = ({ itinerary }) => {
    const [open, setOpen] = useState('1');

    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <Accordion flush open={open} toggle={toggle}>
            {
                itinerary.map(({ attractions, description, meals, title }, index) => (
                    <AccordionItem key={index}>
                        <AccordionHeader targetId={`${index + 1}`}>Day {index + 1} - {title}</AccordionHeader>
                        <AccordionBody accordionId={`${index + 1}`}>
                            <p>{description}</p>
                            {meals && meals.length &&
                                (<div className="d-flex gap-2">
                                    <strong>Meals - </strong>
                                    <div className="d-flex align-items-center gap-2">
                                        {
                                            meals.map((item, index) => (
                                                <Badge className="text-dark" color="light" pill key={index}>{item}</Badge>
                                            ))
                                        }
                                    </div>
                                </div>)
                            }
                            {attractions && <p className="mt-3"><strong>Attractions - </strong> {attractions}</p>}
                        </AccordionBody>
                    </AccordionItem>
                ))
            }
        </Accordion>
    )
}

export default ItineraryCard;