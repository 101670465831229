import useFetchData from '../../hooks/useFetchData';
import NavDowndownItem from './NavDowndownItem';

const NavDropdown = ({ setIsOpen }) => {
    const [isLoading, data] = useFetchData(`${process.env.REACT_APP_CATEGORIES_ENDPOINT}?_no_pagination=true&status__in=Active&_order_by=id`);

    if (isLoading)
        return <></>

    return (
        <>
            {data.map(category => <NavDowndownItem key={category.id} category_type={category.name} setIsOpen={setIsOpen} />)}
        </>
    )
}

export default NavDropdown