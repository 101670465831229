
export const formatNumberAsCurrency = (number) => {
    // Convert the number to a string
    let numStr = number.toString();

    // Check if the number is an integer
    let isNegative = numStr.startsWith('-');
    numStr = isNegative ? numStr.slice(1) : numStr;

    // Split the number into integer and decimal parts
    let [integerPart, decimalPart] = numStr.split('.');

    // Add commas to the integer part
    let result = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // If there is a decimal part, append it
    if (decimalPart) {
        result += '.' + decimalPart;
    }

    // Add the negative sign back if needed
    if (isNegative) {
        result = '-' + result;
    }

    return result;
}

export const convertDateFormat = (dateString) => {
    console.log(dateString);
    // Check if the input string matches the format YYYY-MM-DD
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) {
        throw new Error('Invalid date format. Expected YYYY-MM-DD');
    }

    // Split the input string into components
    const [year, month, day] = dateString.split('-');

    // Return the date in DD-MM-YYYY format
    return `${day}-${month}-${year}`;
}

export const formatDateToDayMonth = (dateString) => {
    // Parse the input date string into a Date object
    const date = new Date(dateString);

    // Options for formatting the date
    const options = { day: '2-digit', month: 'short' };

    // Create a formatter using the options
    const formatter = new Intl.DateTimeFormat('en-GB', options);

    // Format the date and return the result
    return formatter.format(date);
}

export const monthLookup = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
}