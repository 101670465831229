import { Col } from 'reactstrap';
import { formatDateToDayMonth } from '../../../../utils';

const DateRange = ({ start_date, end_date }) => (
    <Col xs="6" md="3" key={start_date + end_date} className="mb-3">
        <div className="p-2 bg-light rounded-3 text-center position-relative">
            <p className="mb-0">
                {formatDateToDayMonth(start_date)} - {formatDateToDayMonth(end_date)}
            </p>
        </div>
    </Col>
)

export default DateRange;